import {BrowserRouter} from "react-router-dom";
import {CriticalStyles} from "./config";
import {lazy, Suspense, useEffect} from "react";
import ReactPixel from 'react-facebook-pixel';
import {clarity} from "react-microsoft-clarity";
import {useIsResponsive} from "./hooks/useIsResponsive";
import {setupGlobalErrorHandlers} from "./error-handlers/errorHandler";
import {CustomErrorBoundary} from "./error-handlers/errorBoundary";
import SiderContextProvider from "./context/SiderContext";
import TagManager from "react-gtm-module";
import { Toaster } from "sonner";

const ApplicationRoutes = lazy(() => import("./config/ApplicationRoutes"));
const AppProviders = lazy(() => import("./config/appProviders"));
const GoogleOAuthProvider = lazy(() => 
  import("@react-oauth/google").then(module => ({ default: module.GoogleOAuthProvider }))
);
const ReactQueryDevtools = lazy(() => 
  import("@tanstack/react-query-devtools").then(module => ({ default: module.ReactQueryDevtools }))
);

const setupAnalytics = () => {
	if (process.env.REACT_APP_ENABLE_PIXEL === "true") {
	  import('react-gtm-module').then(({ default: TagManager }) => {
		TagManager.initialize({"gtmId": "GTM-TFMMCQZV"});
	  });
	  
	  import('react-facebook-pixel').then(({ default: ReactPixel }) => {
		ReactPixel.init('596682932789796');
		ReactPixel.pageView();
	  });
	}
	
	if (process.env.REACT_APP_ENABLE_CLARITY_ID) {
	  import('react-microsoft-clarity').then(({ clarity }) => {
		clarity.init(process.env.REACT_APP_ENABLE_CLARITY_ID as string);
	  });
	}
  };

setupGlobalErrorHandlers();

function App() {

		const { isMobile } = useIsResponsive();
		
		const clientId: string = process.env.REACT_APP_GOOGLE_SSO_CLIEND_ID as string;

		useEffect(() => {
			if (window.requestIdleCallback) {
			  window.requestIdleCallback(setupAnalytics);
			} else {
			  setTimeout(setupAnalytics, 1000);
			}
		  }, []);

		return <BrowserRouter>
					<Suspense fallback={null}>
						<GoogleOAuthProvider clientId={clientId}>
							<SiderContextProvider>
								<AppProviders>
									<CustomErrorBoundary>
										<CriticalStyles isMobile={isMobile}/>
		  								<Suspense fallback={null}>
											<ApplicationRoutes/>
										</Suspense>
										<Toaster expand={true} richColors closeButton toastOptions={{
											style: {
												background: "#FFF"
											}
										}} position="top-right"/>
										{process.env.NODE_ENV === 'development' && (
											<Suspense fallback={null}>
												<ReactQueryDevtools />
											</Suspense>
										)}
									</CustomErrorBoundary>
								</AppProviders>
							</SiderContextProvider>
						</GoogleOAuthProvider>
				</Suspense>
		</BrowserRouter>;
}

export default App;


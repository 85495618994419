import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {QueryClientProvider} from "@tanstack/react-query";

import App from "./App";
import {rootStore} from "./components/redux";
import {injectStoreToProvider} from "./api/tokenHeaderProvider";
import {injectStoreToInterceptors, queryClient} from "./config";
// import "antd/dist/antd.compact.less";
import "./assets/variables.less";
import { Suspense } from "react";


const loadServiceWorker = () => import("./serviceWorker").then(module => module.unregister());

const root = createRoot(document.getElementById("root") as HTMLElement);

injectStoreToInterceptors(rootStore);
injectStoreToProvider(rootStore);

root.render(
    <Provider store={rootStore}>
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={null}>
                <App/>
            </Suspense>
        </QueryClientProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
window.addEventListener('load', () => {
    setTimeout(loadServiceWorker, 1000);
});